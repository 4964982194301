import * as React from "react"
import { Link } from 'gatsby'
import Logo from './Logo'
import { MailOutline, CallOutline, LogoInstagram } from 'react-ionicons'
const Footer = (props) => {

  return (<section class="section footer">

    <div className="container">
      <div class="content">
        <div className="columns">
          <div className="column">
            <p><Logo /></p>
            {/* <p>Sniegts no sirds</p> */}
            <a href="https://www.instagram.com/tryheyday/" className="box">
              <span class="icon ion-icon"><LogoInstagram height="20px" width="20px" color="#874BD8" /></span> Apskati @tryheyday
            </a>
            <a href="mailto:info@heyday.lv" className="box">
              <span class="icon ion-icon"><MailOutline height="20px" width="20px" color="#874BD8" /></span> Raksti info@heyday.lv
            </a>
            <a href="callto:+37122083434" className="box">
              <span class="icon ion-icon"><CallOutline height="20px" width="20px" color="#874BD8" /></span> Zvani
            </a>
            {/* <p><span class="icon ion-icon"><MailOutline /></span> </p>
            <p><span class="icon ion-icon"><CallOutline /></span> </p> */}
            {/* <p><a href="https://www.instagram.com/tryheyday/">Instagram @tryheyday</a></p>
            //  */}

          </div>
          <div className="column has-text-right-tablet">

            <p>Heyday 2021</p>
            <p>
              <Link to="/terms">Noteikumi</Link>
            </p>
            <p><Link to="/privacy">Privātuma politika</Link></p>

            <p><a href="https://www.twitter.com/tryheyday/">Twitter @tryheyday</a></p>

            <p><small>Reģ. nr. LV40203274222</small></p>

          </div>
        </div>
      </div>

    </div>
  </section>
  )
}

export default Footer

