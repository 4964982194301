import * as React from "react"
import { Link } from "gatsby"
import { CheckmarkSharp } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { navigate } from "gatsby"


const PaymentSecurity = (props) => {
    const data = useStaticQuery(graphql`
    query {
      visa: file(relativePath: { eq: "visa.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      applepay: file(relativePath: { eq: "apple-pay.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      googlepay: file(relativePath: { eq: "google-pay.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      mc: file(relativePath: { eq: "mc.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      },
    }
  `)

    
    return (<div>
        <p>
        <Img fixed={data.visa.childImageSharp.fixed} class="Visa logo" />  &nbsp;
        <Img fixed={data.mc.childImageSharp.fixed} class="Mastercard logo" /> &nbsp;
        <Img fixed={data.applepay.childImageSharp.fixed} class="Apple Pay logo" />  &nbsp;
        <Img fixed={data.googlepay.childImageSharp.fixed} class="Google Pay logo" /> 

        </p>

      Droša apmaksa
    </div>)
}

export default PaymentSecurity