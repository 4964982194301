import * as React from "react"
import { navigate } from "gatsby"
import {CheckoutContext} from "../context/CheckoutContext"
import {useContext} from 'react'

const EndCheckout = (props) => {

    const handleClick = (e) => {
        e.preventDefault();
        context.resetAll();
        navigate('/')

    }

    const context = useContext(CheckoutContext)

    return (<button class="delete is-large is-pulled-right" onClick={handleClick}></button>)
}

export default EndCheckout