import * as React from "react"
import Img from "gatsby-image"
import { Star } from 'react-ionicons'

const Stars = () => {
    return (<>
<Star
  color={'#FFD281'} 
  height="13px"
  width="13px"
/>
<Star
  color={'#FFD281'} 
  height="13px"
  width="13px"
/>
<Star
  color={'#FFD281'} 
  height="13px"
  width="13px"
/>
<Star
  color={'#FFD281'} 
  height="13px"
  width="13px"
/>
<Star
  color={'#FFD281'} 
  height="13px"
  width="13px"
/>
    </>)
}

const Testimonials = () => {


  return (


    <div className="testimonials content ">

        <div className="columns">
            <div className="column">
                {/* <p>"Fantastiska prezentācija un serviss, ceru ka tas saglabāsies tikpat labā līmenī. Viegli sekot instrukcijai un ļoti skaists rezultāts."</p> */}
                <p>Kristina novērtēja: "Viegli sekot instrukcijai un ļoti skaists rezultāts."</p>
                <Stars />

            </div>
        </div>
        
    </div>





  )
}

export default Testimonials
