import React, { useEffect, useContext, useState } from 'react';
import {CheckoutContext} from "../context/CheckoutContext"
import { navigate } from "gatsby"

const Auth = (props) => {
    const context = useContext(CheckoutContext)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
  

    const _handleClick = (e) => {
        e.preventDefault();
        context.resetAll();
        navigate(props.to ? props.to : "/")
    }

    useEffect(() => {

        setLoading(false);
        setError(null);
        
        if(!context.sessionToken && !props.required) {
            return;
        }
        

        if(!context.sessionToken && props.required) {
            setError('Ir nepieciešams pieslēgties lietotāja kontam.');
            return
        }

        setLoading(true);

        fetch(`${process.env.GATSBY_API_BASE}/verif/user/info`, { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${context.sessionToken}`
            }
          })
          .then(res => res.json())
          .then(
            (result) => {

                setLoading(false);       
                // result.detail == "Invalid token"
                if(!result.token) {
                    console.log('error in token info')

                    if(props.silent) {
                        context.resetAll();
                        navigate(props.to ? props.to : "/")
                    } else {
                        setError('Ir beigusies lietotāja sesija. Lūdzu pārlādē lapu, lai turpinātu.');
                    }

                }

              
            },
            (error) => {
                console.error('failed;')
                setLoading(false);
              
            }
          )
  


    }, [context.sessionToken]);


    return (
        <div>
            {loading && !props.silent ? <div class="modal is-active ">
            <div class="modal-background"></div>
            <div class="modal-card modal-error">
                <section class="modal-card-body has-text-centered content">
                    <progress class="progress is-small " max="100">15%</progress>

                </section>
            </div>
            </div> : null}

            {error ? <div class="modal is-active ">
            <div class="modal-background"></div>
            <div class="modal-card modal-error">
                <section class="modal-card-body has-text-centered content">
                            <p>{error}</p>
                    <button class="button is-primary is-large" aria-label="close" onClick={_handleClick}>Pārlādēt</button>
                </section>
            </div>
            </div> : null}

            {props.children}
        </div>
    );
}

export default Auth