import * as React from "react"
import { useState, useEffect } from 'react'
import { Link } from "gatsby"
import { CheckmarkSharp } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import Footer from '../components/Footer.js'
import PaymentSecurity from '../components/PaymentSecurity.js'
import { useContext } from 'react'
import { CheckoutContext } from "../context/CheckoutContext"
import EndCheckout from '../components/EndCheckout.js'
import Progress from '../components/Progress.js'
import Testimonial from '../components/Testimonial.js'
import Auth from '../components/Auth'


// markup
const Example = (props) => {



  return (<>
    <div class="columns is-mobile  has-text-centered content  ">


      <div class="column ">
        <p><Img fluid={props.data.samplewhite.childImageSharp.fluid} class="example" /></p>
        <p>29 ziedi</p>
        <p class="latin">Rozes baltā, gaiši rozā, sarkanā krāsā, waxflower, alstromērijas, pandas un parvifolia eikalipts</p>
      </div>
      <div class="column ">
        <p><Img fluid={props.data.sampleorange.childImageSharp.fluid} class="example" /></p>
        <p>23 ziedi</p>
        <p class="latin">Rozes harmony peach, waxflower, aromātiskās genista pink, alstromērijas un zaļumi</p>
      </div>


    </div>
  </>)
}



const PlanSelection = (props) => {
  const _handleOnClick = (e) => {
    e.preventDefault();
    props.onClick(props.plan)
  }
  return (
    <a href="#" class={props.selected ? "button is-primary is-fullwidth is-medium is-rounded" : "button is-primary is-fullwidth is-medium is-rounded"} onClick={_handleOnClick}>
      Turpināt
    </a>
    // <a href="#" class={props.selected ? "button is-success is-fullwidth is-rounded" : "button is-fullwidth is-rounded"} onClick={_handleOnClick}>
    //   <span class="icon is-small pr-5">
    //     {props.selected ? <span class="ion-ionic">
    //       <CheckmarkSharp color="#fff" />
    //     </span> : null}

    //   </span>
    //   {props.selected ? "" : "Izvēlēties"}
    //   {/* // Tu izvēlējies šo */}
    // </a>
  )
}


const Subscribe = () => {
  const context = useContext(CheckoutContext)




  const handlePlanClick = (plan) => {
    context.setPlan(plan);
    navigate("/register")
  }

  const handlePlan1xClick = () => {
    context.setPlan('1x');
  }
  const handlePlan2xClick = () => {
    context.setPlan('2x');
  }
  const handlePlan4xClick = () => {
    context.setPlan('4x');
  }

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      },


      testimonial: file(relativePath: { eq: "photos/week1/week1-1.jpg" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      },



      one: file(relativePath: { eq: "photos/compositions/trial1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      two: file(relativePath: { eq: "photos/compositions/trial2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      three: file(relativePath: { eq: "photos/compositions/week1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    }
  `)

    const plan = context.getPlanDetails()
  return (
    <main class=" checkout checkout__keyboard">
      <section class="section">

        <div className="container">

        <Helmet title="Heyday ziedu piegāde" defer={false} />
        <Auth silent to="/subscribe" />

        <div class="columns is-mobile">
          <div class="column pt-0">
            <Progress value={20} />
          </div>
          <div class="column is-one-third pt-0">
            <EndCheckout />
          </div>
        </div>


        {/* <h1 class="title is-1">Piegādes biežums</h1> */}

        <div class="content">

          <div class="columns is-desktop">
            <div class="column ">
              <div class="card checkout-col">
                <div class="card-content">

                <div className="columns is-mobile">
                  <div className="column has-text-left">
                    <Testimonial />
                  </div>
                  <div className="column is-narrow">
                    <Img fixed={data.testimonial.childImageSharp.fixed}  /> 
                  </div>
                </div>

                {/* <hr />  */}

                <p>
                  <strong>Izvēlies piegādes biežumu:</strong>
                </p>

                <div class="field plan-sel" onClick={handlePlan1xClick}>
                    <input class="is-checkradio" id="1x" type="radio" name="period" checked={context.plan === '1x' ? "checked" : ""} />
                    <label for="other" class="radio-label">
                      Vienu reizi mēnesī
                      <br/>
                      <small>€35 mēnesī</small>
                    </label>
                </div>

                <div class="field plan-sel" onClick={handlePlan2xClick}>
                    <input class="is-checkradio" id="2x" type="radio" name="period" checked={context.plan === '2x' ? "checked" : ""} />
                    <label for="other" class="radio-label">
                      Divas reizes mēnesī
                      <br/>
                      <small>Kopā €70 mēnesī</small>
                    </label>
                </div>

                <div class="field plan-sel" onClick={handlePlan4xClick}>
                    <input class="is-checkradio" id="4x" type="radio" name="period" checked={context.plan === '4x' ? "checked" : ""} />
                    <label for="other" class="radio-label">
                      Reizi nedēļā
                      <br/>
                      <small>Kopā €140 mēnesī</small>
                    </label>
                </div>



                  {/* <PlanSelection plan="1x" selected={context.plan === '1x'} onClick={handlePlanClick} /> */}

                  <Link to="/register" class="button is-primary is-rounded is-large is-fullwidth mb-2">Turpināt</Link>

                  <hr />

                  <ul>
                    <li>Automātiska apmaksa €35 pirms piegādes</li>
                    <li>Piegāde ar kurjeru {plan.period.toLowerCase()}, ceturtdienā</li>
                    {/* <li>Vienas saņemšanas maksa ir €35</li> */}
                    {/* <li>Apmaksa €35 pirms katras piegādes ir automātiska</li> */}
                    {/* <li>€35 par katru piegādi = €{plan.monthlyPrice} mēnesī</li> */}
                    {/* <li>Automātiska apmaksa pirms piegādes</li> */}
                    {/* <li>Apmierinātības garantija</li> */}
                    <li>Bez saistībām - brīvi maini vai atcel</li>
                    <li>Piegāde ārpus Rīgas €5, pievienojama turpinot</li>
                    </ul>




                </div>

              </div>

            </div>

           





          </div>












          <div class=" has-text-centered">
            <PaymentSecurity />
            
          </div>


        </div>

        </div>

      </section>


      <section class="section" style={{backgroundColor: '#fff'}}>

      <div className="container">


       <div class="columns is-desktop">
            <div className="column">
              <h2 class="title is-4">Kas iekļauts piegādē?</h2>
              <div className="content">
                <ul>
                  <li>20-30 svaigi, dažādi, saskaņoti ziedi un zaļumi, ko viegli sakārtot vāzē</li>
                  <li>Florista instrukcija kompozīcijas izveidei</li>
                  <li>Iepakojums piemērots dāvināšanai</li>
                  <li>Ziedu barības pulveris</li>
                </ul>
              </div>

            </div>
            <div className="column content">
              <h2 class="title is-4">Piemēri no iepriekšējām kompozīcijām</h2>
              <p> Ziedu skaits var mainīties, bet no tiem varēs izveidot vienu lielu, vai divas mazākas kompozīcijas.</p>

              <div class="columns is-mobile">

                <div class="column">
                  <p><Img fluid={data.one.childImageSharp.fluid} class="sample" /></p>
                  <p>23 ziedi</p>
                  <p class="latin">Rozes harmony peach, waxflower, aromātiskās genista pink, alstromērijas un zaļumi</p>
                </div>

                <div class="column ">
                  <p><Img fluid={data.two.childImageSharp.fluid} class="sample" /></p>
                  <p>29 ziedi</p>
                  <p class="latin">Rozes baltā, gaiši rozā, sarkanā krāsā, waxflower, alstromērijas, pandas un parvifolia eikalipts</p>
                </div>

                <div class="column ">
                  <p><Img fluid={data.three.childImageSharp.fluid} class="sample" /></p>
                  <p>23 ziedi</p>
                  <p class="latin">Āzijas gundega jeb Ranunculus, lefkojas, lizantes, rozes aqua, brunia albiflora, vaska puķe un parvifolia eikalipts</p>
                </div>


              </div>



            </div>
          </div>
        
      </div>
      </section>

      {/* <ContinueCheckout continue={"/register"} /> */}
      <Footer />

    </main>
  )
}

export default Subscribe
