import * as React from "react"


const Progress = (props) => {
    const totalSteps = 3;
    return (<>
        <progress class="progress is-small mb-1 mt-2" value={props.value} max="100">{props.value}%</progress>
        <p>Solis {Math.ceil(props.value / 100 * totalSteps)} no {totalSteps}</p>
    </>)
}

export default Progress

